import qs from "qs";
import {
  FormEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { useAuth } from "../contexts/AuthContext";
import { useNavigationDirty } from "../contexts/NavigationDirtyContext";
import { NotificationTypes } from "../contexts/NotificationContext";
import { usePendingComputerUpdate } from "../contexts/PendingComputerUpdateContext";
import {
  RightPanelContext,
  RightPanelContextProps,
} from "../contexts/RightPanelContext";
import { PolicySeverityEnum } from "../enum/PolicySeverityEnum";
import { PolicyTypeEnum } from "../enum/PolicyTypeEnum";
import Computer from "../interfaces/Computer";
import ComputerGroup from "../interfaces/ComputerGroup";
import PolicySeverity from "../interfaces/PolicySeverity";
import SecurityPolicy from "../interfaces/SecurityPolicy";
import User from "../interfaces/User";
import { InfoService } from "../services/infoService";
import { SecurityPolicyService } from "../services/securityPolicyService";
import { EventBus, EventBusEvents } from "../utils/EventBus";
import { policies } from "../utils/policies";
import { policyFields } from "../utils/policyFields";
import { policySeverities } from "../utils/policySeverities";
import { specialFolders } from "../utils/specialFolders";

import { useNotifications } from "./useNotifications";
import useServerData from "./useServerData";

const securityPolicyService = new SecurityPolicyService();
const infoService = new InfoService();

interface Props {
  id: string | number;
  onSaved: () => void;
  onCancel: () => void;
  selectedPolicyTypeEnum?: PolicyTypeEnum;
}

interface FormState {
  type: number | bigint | null;
  name?: string;
  description?: string;
  users: User[];
  computers: Computer[];
  computerGroups: ComputerGroup[];
  priority: number;
  permissions: Record<string, any>;
  severity?: PolicySeverityEnum;
}

function useSecurityPolicyDetail({
  id,
  onSaved,
  onCancel,
  selectedPolicyTypeEnum,
}: Props) {
  const [securityPolicy, setSecurityPolicy] = useState<SecurityPolicy | null>(
    null,
  );
  const { isRightPanelOpen } = useContext(
    RightPanelContext,
  ) as RightPanelContextProps;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const [permissionErrors, setPermissionErrors] = useState<
    Record<string, string>
  >({});
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [permissionDisabledFields, setPermissionDisabledFields] = useState<
    string[]
  >([]);
  const [permissionLoadingFields, setPermissionLoadingFields] = useState<
    string[]
  >([]);
  const formRef = useRef<HTMLFormElement>(null);
  const [form, setForm] = useState<FormState>({
    computerGroups: [],
    computers: [],
    description: "",
    name: "",
    permissions: {
      enable: false,
      notification: false,
      optionExcept: false,
    },
    priority: 1,
    type:
      typeof selectedPolicyTypeEnum !== "undefined"
        ? selectedPolicyTypeEnum
        : null,
    users: [],
  });
  const { createNotification } = useNotifications();
  const { settings } = useServerData();
  const { t } = useTranslation();
  const { setDirty, confirmDirtyNavigation } = useNavigationDirty();
  const { isCurrentUserReadOnly } = useAuth();
  const { setHasPendingUpdates } = usePendingComputerUpdate();

  /**
   * Resets the form to its initial state.
   *
   * @function
   * @name resetForm
   *
   * @returns {void}
   *
   * @description
   * The `resetForm` function is a callback function that is used to reset the form to its initial state. It sets the form state with an empty `computerGroups` array, empty `computers
   *` array, empty `description`, empty `name`, permissions object with `enable` and `optionExcept` set to `false`, `priority` set to `1`, `type` set to the value of `selectedPolicyTypeEnum
   *` if it is defined, otherwise `null`, and `users` array.
   *
   * @example
   * resetForm();
   */
  const resetForm = useCallback(() => {
    setForm({
      computerGroups: [],
      computers: [],
      description: "",
      name: "",
      permissions: {
        enable: false,
        notification: false,
        optionExcept: false,
      },
      priority: 1,
      severity: PolicySeverityEnum.low,
      type:
        typeof selectedPolicyTypeEnum !== "undefined"
          ? selectedPolicyTypeEnum
          : null,
      users: [],
    });
  }, [selectedPolicyTypeEnum]);

  /**
   * Handle cancellation of an operation.
   *
   * @async
   * @return {Promise<void>} A `Promise` that resolves when the cancellation
   * is handled.
   */
  const handleCancel = async (): Promise<void> => {
    const confirm = await confirmDirtyNavigation();
    if (confirm) {
      resetForm();
      onCancel();
    }
  };

  /**
   * Updates the form field value, based on the field type.
   * @param {string} field - The name of the field to update.
   * @param {any} value - The new value for the field.
   * @returns {void}
   */
  const handleInputChange = (field: string, value: any): void => {
    switch (field) {
      case "type":
        setForm({
          ...form,
          permissions: {
            enable: false,
            notification: true,
            optionExcept: false,
          },
          type: value,
        });
        break;
      default:
        setForm((prevForm) => ({ ...prevForm, [field]: value }));
        break;
    }
    setDirty(true);
  };

  /**
   * Handles the input action for a given field.
   * @param {string} field - The field to perform the action on.
   * @returns {Promise<void>} - A promise that resolves with void.
   */
  const handleInputAction = async (field: string): Promise<void> => {
    switch (form.type) {
      case PolicyTypeEnum.Network:
        setPermissionLoadingFields([...permissionLoadingFields, "wizardType"]);
        await infoService
          .getWizard(
            qs.stringify({
              policyType: form.type,
              wizardType: form.permissions.wizardType,
            }),
          )
          .then((res) => {
            const existingDomains = form.permissions.domains || [];
            res?.domains?.map((domain: string) => {
              if (!existingDomains.includes(domain)) {
                existingDomains.push(domain);
              }
              return domain;
            });
            setForm((prevForm) => ({
              ...prevForm,
              permissions: {
                ...prevForm.permissions,
                domains: existingDomains,
                optionExcept:
                  res?.optionExcept || prevForm.permissions.optionExcept,
                wizardType: undefined,
              },
            }));
          })
          .catch(() => {
            createNotification(
              t("errors.genericUpdateError", { resource: t("common.policy") }),
              NotificationTypes.DANGER,
            );
          })
          .finally(() => {
            setPermissionLoadingFields(
              permissionLoadingFields.filter((item) => item !== "wizardType"),
            );
          });
        break;
      default:
        break;
    }
  };
  /**
   * @desc Handles the form submission
   * @param e
   */
  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const updatedForm = structuredClone(form);

    switch (form.type) {
      case PolicyTypeEnum.FileAccessApplication:
      case PolicyTypeEnum.UploadNewFileExe:
      case PolicyTypeEnum.DeleteFile:
        {
          const extensionKeys = ["extensions", "customExtensions"];
          const allExtensions: string[] = [];
          let allFolders: string[] = [];

          Object.keys(updatedForm.permissions).forEach((key) => {
            const items = updatedForm.permissions[key];

            if (extensionKeys.includes(key) && Array.isArray(items)) {
              items.forEach((item) => {
                if (typeof item === "object" && "value" in item) {
                  allExtensions.push(item.value);
                } else if (typeof item === "string") {
                  allExtensions.push(item);
                }
              });
            }

            if (key === "folders" && Array.isArray(items)) {
              const mappedFolders = items.map(
                (obj: { item1: string; item2: string }): string =>
                  `${obj.item1}\\${obj.item2}`,
              );
              allFolders = [...allFolders, ...mappedFolders];
            }

            if (key === "customFolders" && Array.isArray(items)) {
              allFolders = [...allFolders, ...items];
            }

            if (key === "certificates" && Array.isArray(items)) {
              updatedForm.permissions.certificates = items.map(
                (obj: { id: number; value: string }): string => `${obj.id}`,
              );
            }

            if (
              ["exceptInclude", "exceptExclude"].includes(key) &&
              Array.isArray(items)
            ) {
              updatedForm.permissions[key] = items.map(
                (obj: { item1: string; item2: string }): string =>
                  `${obj.item1}\\${obj.item2}`,
              );
            }

            if (key === "customCertificates" && Array.isArray(items)) {
              updatedForm.permissions.customCertificates = items.reduce(
                (
                  acc: { [x: string]: string },
                  obj: { item1: string | number; item2: any },
                ) => {
                  acc[obj.item1] = obj.item2;
                  return acc;
                },
                {},
              );
            }

            if (key === "hashes" && Array.isArray(items)) {
              updatedForm.permissions.hashes = items.reduce(
                (
                  acc: { [x: string]: string },
                  obj: { item1: string | number; item2: any },
                ) => {
                  acc[obj.item1] = obj.item2;
                  return acc;
                },
                {},
              );
            }
          });

          updatedForm.permissions.extensions = allExtensions;
          updatedForm.permissions.folders = allFolders;
          delete updatedForm.permissions.customExtensions;
          delete updatedForm.permissions.customFolders;
        }
        break;
      case PolicyTypeEnum.DownloadFile:
        updatedForm.permissions.extensions = ["*"];
        delete updatedForm.permissions.allExtensions;
        break;
      case PolicyTypeEnum.AccessFolders:
        {
          let allFolders: string[] = [];

          if (
            updatedForm.permissions.folders &&
            updatedForm.permissions.folders.length > 0
          ) {
            allFolders = updatedForm.permissions.folders.map(
              (obj: { item1: string; item2: string }): string =>
                `${obj.item1}\\${obj.item2}`,
            );
          }
          // Push the entire array of absolutePaths inside all folders
          if (
            updatedForm.permissions.absolutePaths &&
            updatedForm.permissions.absolutePaths.length > 0
          ) {
            allFolders = [
              ...updatedForm.permissions.absolutePaths,
              ...allFolders,
            ];
          }

          // Revert visible flag
          updatedForm.permissions.visible = !updatedForm.permissions.visible;
          updatedForm.permissions.folders = allFolders;
          delete updatedForm.permissions.absolutePaths;
        }
        break;
      case PolicyTypeEnum.ProxyControl: {
        if (updatedForm.permissions?.setProxy) {
          updatedForm.permissions.proxyData = {
            address: updatedForm.permissions.address,
            port: updatedForm.permissions.port,
          };
        }
        delete updatedForm.permissions.address;
        delete updatedForm.permissions.port;
        break;
      }
      case PolicyTypeEnum.EncryptionFile:
        {
          const extensionKeys = ["extensions", "customExtensions"];
          const allExtensions: string[] = [];
          let allFolders: string[] = [];

          if (!updatedForm.permissions.filegrantData) {
            updatedForm.permissions.filegrantData = {};
          }

          Object.keys(updatedForm.permissions).forEach((key) => {
            const items = updatedForm.permissions[key];

            if (extensionKeys.includes(key) && Array.isArray(items)) {
              items.forEach((item) => {
                if (typeof item === "object" && "value" in item) {
                  allExtensions.push(item.value);
                } else if (typeof item === "string") {
                  allExtensions.push(item);
                }
              });
            }

            if (key === "folders" && Array.isArray(items)) {
              const mappedFolders = items.map(
                (obj: { item1: string; item2: string }): string =>
                  `${obj.item1}\\${obj.item2}`,
              );
              allFolders = [...allFolders, ...mappedFolders];
            }

            if (key === "customFolders" && Array.isArray(items)) {
              allFolders = [...allFolders, ...items];
            }

            if (key === "certificate") {
              // find certificate inside permissions.certificates
              updatedForm.permissions.filegrantData.certificate =
                updatedForm.permissions.certificates.find(
                  (certificate: { id: string }) =>
                    certificate.id.toString() === items.toString(),
                );
            }

            if (key === "accessRule") {
              updatedForm.permissions.filegrantData.accessRule =
                updatedForm.permissions.accessRules.find(
                  (rule: { id: string }) =>
                    rule.id.toString() === items.toString(),
                );
            }

            if (key === "apiKey") {
              updatedForm.permissions.filegrantData.apiKey = items;
            }

            if (key === "company") {
              updatedForm.permissions.filegrantData.company = items;
            }
          });

          updatedForm.permissions.extensions = allExtensions;
          updatedForm.permissions.folders = allFolders;
          delete updatedForm.permissions.customExtensions;
          delete updatedForm.permissions.customFolders;
          delete updatedForm.permissions.certificate;
          delete updatedForm.permissions.accessRule;
          delete updatedForm.permissions.certificates;
          delete updatedForm.permissions.accessRules;
          delete updatedForm.permissions.apiKey;
          delete updatedForm.permissions.company;
        }
        break;
      case PolicyTypeEnum.SecureBrowser:
        {
          updatedForm.permissions.authentication = {
            password: updatedForm.permissions.password,
            type: updatedForm.permissions.authentication,
          };
          delete updatedForm.permissions.password;
        }

        break;
      default:
        break;
    }

    setIsLoading(true);
    if (id) {
      await securityPolicyService
        .updateSecurityPolicy(id, { ...updatedForm })
        .then(() => {
          createNotification(
            t("notifications.genericUpdateSuccess", {
              resource: t("common.policy"),
            }),
            NotificationTypes.SUCCESS,
          );
          setDirty(false);
          onSaved();
          setHasPendingUpdates(true);
        })
        .catch(() => {
          createNotification(
            t("errors.genericUpdateError", { resource: t("common.policy") }),
            NotificationTypes.DANGER,
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      await securityPolicyService
        .createSecurityPolicy({ ...updatedForm })
        .then(() => {
          createNotification(
            t("notifications.genericCreateSuccess", {
              resource: t("common.policy"),
            }),
            NotificationTypes.SUCCESS,
          );
          setHasPendingUpdates(true);
          setDirty(false);
          resetForm();
          onSaved();
        })
        .catch(() => {
          createNotification(
            t("errors.genericCreateError", { resource: t("common.policy") }),
            NotificationTypes.DANGER,
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  /**
   * @desc Fetches the filegrant data from the API
   */
  const fetchFilegrantData = async () => {
    const headers = new Headers({
      ApiKey: form.permissions.apiKey,
      "Content-Type": "application/json",
    });

    const requestOptions: RequestInit = {
      headers: headers,
      method: "GET",
    };

    const urls = [
      `${import.meta.env.VITE_APP_FILEGRANT_API_DOMAIN}/api/infoapikey`,
      `${import.meta.env.VITE_APP_FILEGRANT_API_DOMAIN}/api/certificates`,
      `${import.meta.env.VITE_APP_FILEGRANT_API_DOMAIN}/api/accessrules`,
    ];

    try {
      setPermissionLoadingFields([
        ...permissionLoadingFields,
        "apiKey",
        "certificate",
        "accessRule",
      ]);
      const fetchPromises = urls.map((url) => fetch(url, requestOptions));

      const responses = await Promise.all(fetchPromises);

      const data = await Promise.all(
        responses.map((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        }),
      );

      setForm((prevForm) => ({
        ...prevForm,
        permissions: {
          ...prevForm.permissions,
          accessRule: "",
          accessRules: data?.[2] || [],
          certificate: "",
          certificates: data?.[1] || [],
          company: data[0],
        },
      }));
      policyFields[PolicyTypeEnum.EncryptionFile]?.forEach((field) => {
        if (field.id === "accessRule") {
          field.options = data[2].map((item: any) => ({
            id: item.id,
            value: item.name,
          }));
          EventBus.emit(EventBusEvents.ON_SECURITY_POLICY_OPTIONS_CHANGE, {
            fieldId: field.id,
            options: field.options,
          });
        }
        if (field.id === "certificate") {
          field.options = data[1].map((item: any) => ({
            id: item.id,
            value: item.name,
          }));
          EventBus.emit(EventBusEvents.ON_SECURITY_POLICY_OPTIONS_CHANGE, {
            fieldId: field.id,
            options: field.options,
          });
        }
      });
    } catch (error) {
      // Remove selected values

      setForm((prevForm) => ({
        ...prevForm,
        permissions: {
          ...prevForm.permissions,
          accessRule: "",
          accessRules: [],
          certificate: "",
          certificates: [],
          company: null,
        },
      }));

      policyFields[PolicyTypeEnum.EncryptionFile]?.forEach((field) => {
        if (field.id === "accessRule") {
          field.options = [];
          EventBus.emit(EventBusEvents.ON_SECURITY_POLICY_OPTIONS_CHANGE, {
            fieldId: field.id,
            options: [],
          });
        }
        if (field.id === "certificate") {
          field.options = [];
          EventBus.emit(EventBusEvents.ON_SECURITY_POLICY_OPTIONS_CHANGE, {
            fieldId: field.id,
            options: [],
          });
        }
      });
    }

    setPermissionLoadingFields(
      permissionLoadingFields.filter(
        (item) =>
          item !== "apiKey" && item !== "certificate" && item !== "accessRule",
      ),
    );
  };

  /**
   * Updates the permissions field in the form with the new value for the specified field ID.
   *
   * @param {string} fieldId - The ID of the field to update.
   * @param {any} newValue - The new value for the specified field.
   * @returns {void}
   */
  const handlePermissionsEdit = useCallback(
    (fieldId: string, newValue: any) => {
      setForm((prevForm) => ({
        ...prevForm,
        permissions: {
          ...prevForm.permissions,
          [fieldId]: newValue,
        },
      }));
      setDirty(true);
    },
    [setDirty],
  );

  /**
   * @desc Fetches user data from the API
   */
  const getSecurityPolicy = useCallback(async () => {
    setIsLoading(true);
    try {
      const [securityPolicyRes] = await Promise.all([
        securityPolicyService.getSecurityPolicy(id),
      ]);
      const updatedSecurityPolicy = structuredClone(securityPolicyRes);

      switch (updatedSecurityPolicy.type) {
        case PolicyTypeEnum.FileAccessApplication:
        case PolicyTypeEnum.UploadNewFileExe:
        case PolicyTypeEnum.DeleteFile:
          {
            const permissions = updatedSecurityPolicy.permissions;

            // Recreate the structure for 'customExtensions'
            let fileTypesArray: string[] = [];

            for (const key in settings?.fileTypes) {
              fileTypesArray = [...fileTypesArray, ...settings.fileTypes[key]];
            }

            // Filter out the custom extensions that are not in the file types array
            permissions.customExtensions = permissions?.extensions?.filter(
              (x: string) => !fileTypesArray.includes(x),
            );

            // Recreate the structure for 'extensions' by removing custom extension
            permissions.extensions = permissions?.extensions
              ?.filter(
                (p: string) => !permissions?.customExtensions?.includes(p),
              )
              .map((extension: any) => ({
                id: extension,
                value: extension,
              }));

            const recreatedFolders: { item1: string; item2: string }[] = [];
            const recreatedCustomFolders: string[] = [];

            permissions.folders.forEach((folder: string) => {
              const parts = folder.split("\\");
              const firstPart = parts[0];
              const restPart = parts.slice(1).join("\\");

              const specialFolder = specialFolders.find(
                (special) => special.id === firstPart,
              );

              if (specialFolder) {
                recreatedFolders.push({
                  item1: firstPart,
                  item2: restPart,
                });
              } else {
                recreatedCustomFolders.push(folder);
              }
            });

            permissions.folders = recreatedFolders;
            permissions.customFolders = recreatedCustomFolders;

            // Recreate the structure for "exceptInclude" and "exceptExclude"
            const recreatedExceptInclude: { item1: string; item2: string }[] =
              [];
            const recreatedExceptExclude: { item1: string; item2: string }[] =
              [];

            permissions?.exceptInclude?.forEach((folder: string) => {
              const parts = folder.split("\\");
              const firstPart = parts[0];
              const restPart = parts.slice(1).join("\\");
              recreatedExceptInclude.push({
                item1: firstPart,
                item2: restPart,
              });
            });

            permissions?.exceptExclude?.forEach((folder: string) => {
              const parts = folder.split("\\");
              const firstPart = parts[0];
              const restPart = parts.slice(1).join("\\");
              recreatedExceptExclude.push({
                item1: firstPart,
                item2: restPart,
              });
            });

            permissions.exceptInclude = recreatedExceptInclude;
            permissions.exceptExclude = recreatedExceptExclude;

            // Check if there are any folders or custom folders
            if (
              permissions?.folders?.length === 0 &&
              permissions?.customFolders?.length === 0
            ) {
              permissions.monitorSpecificFolders = false;
            } else {
              permissions.monitorSpecificFolders = true;
            }
            // Recreate the structure for 'certificates'
            permissions.certificates =
              permissions?.certificates?.map((identifier: any) => ({
                id: identifier,
                value:
                  settings?.certifiedApplications?.[identifier]?.name || "",
              })) || [];

            // Revert the parsing of the permissions field for file access applications policy
            ["customCertificates", "hashes"].forEach((field) => {
              if (!permissions[field]) {
                permissions[field] = [];
                return;
              }

              permissions[field] = Object.entries(permissions[field]).map(
                ([item1, item2]) => ({ item1, item2 }),
              );
            });

            // Check confirmMessage
            if (permissions.confirmMessage) {
              permissions.confirmCustomMessage = true;
            }

            updatedSecurityPolicy.permissions = permissions;
          }
          break;
        case PolicyTypeEnum.DateTimePlan:
          if (Array.isArray(updatedSecurityPolicy.permissions.days)) {
            updatedSecurityPolicy.permissions.days =
              updatedSecurityPolicy.permissions.days.map((item: string) =>
                String(item),
              );
          } else {
            updatedSecurityPolicy.permissions.days = [];
          }
          break;
        case PolicyTypeEnum.ExternalDrives:
          {
            const permissions = updatedSecurityPolicy.permissions;

            // Check confirmMessage
            if (permissions.confirmMessage) {
              permissions.confirmCustomMessage = true;
            }
            updatedSecurityPolicy.permissions = permissions;
          }
          break;
        case PolicyTypeEnum.AccessFolders:
          {
            const permissions = updatedSecurityPolicy.permissions;
            const recreatedFolders: { item1: string; item2: string }[] = [];
            const recreatedAbsolutePaths: string[] = [];

            permissions.folders.forEach((folder: string) => {
              const parts = folder.split("\\");
              const firstPart = parts[0];
              const restPart = parts.slice(1).join("\\");

              const specialFolder = specialFolders.find(
                (special) => special.id === firstPart,
              );

              if (specialFolder) {
                recreatedFolders.push({
                  item1: firstPart,
                  item2: restPart,
                });
              } else {
                recreatedAbsolutePaths.push(folder);
              }
            });

            permissions.folders = recreatedFolders;
            permissions.absolutePaths = recreatedAbsolutePaths;
            permissions.visible = !permissions.visible;
            updatedSecurityPolicy.permissions = permissions;
          }
          break;
        case PolicyTypeEnum.ProxyControl: {
          const permissions = updatedSecurityPolicy.permissions;

          if (permissions?.setProxy) {
            permissions.address = permissions.proxyData.address;
            permissions.port = permissions.proxyData.port;
          }
          delete permissions.proxyData;
          break;
        }
        case PolicyTypeEnum.EncryptionFile:
          {
            const permissions = updatedSecurityPolicy.permissions;

            // Recreate the structure for 'customExtensions'
            let fileTypesArray: string[] = [];

            for (const key in settings?.fileTypes) {
              fileTypesArray = [...fileTypesArray, ...settings.fileTypes[key]];
            }

            // Filter out the custom extensions that are not in the file types array
            permissions.customExtensions = permissions?.extensions?.filter(
              (x: string) => !fileTypesArray.includes(x),
            );

            // Recreate the structure for 'extensions' by removing custom extension
            permissions.extensions = permissions?.extensions
              ?.filter(
                (p: string) => !permissions?.customExtensions?.includes(p),
              )
              .map((extension: any) => ({
                id: extension,
                value: extension,
              }));

            const recreatedFolders: { item1: string; item2: string }[] = [];
            const recreatedCustomFolders: string[] = [];

            permissions.folders.forEach((folder: string) => {
              const parts = folder.split("\\");
              const firstPart = parts[0];
              const restPart = parts.slice(1).join("\\");

              const specialFolder = specialFolders.find(
                (special) => special.id === firstPart,
              );

              if (specialFolder) {
                recreatedFolders.push({
                  item1: firstPart,
                  item2: restPart,
                });
              } else {
                recreatedCustomFolders.push(folder);
              }
            });

            // Check if there are any folders or custom folders
            if (
              permissions?.folders?.length === 0 &&
              permissions?.customFolders?.length === 0
            ) {
              permissions.monitorSpecificFolders = false;
            } else {
              permissions.monitorSpecificFolders = true;
            }

            permissions.folders = recreatedFolders;
            permissions.customFolders = recreatedCustomFolders;
            permissions.apiKey = permissions.filegrantData.apiKey;
            permissions.certificate =
              permissions?.filegrantData?.certificate?.id;
            permissions.accessRule = permissions?.filegrantData?.accessRule?.id;
            permissions.certificates = [
              permissions?.filegrantData?.certificate,
            ];
            permissions.accessRules = [permissions?.filegrantData?.accessRule];
            // Set options
            policyFields[PolicyTypeEnum.EncryptionFile]?.forEach((field) => {
              if (field.id === "accessRule") {
                field.options = [
                  {
                    id: permissions?.filegrantData?.accessRule?.id,
                    value: permissions?.filegrantData?.accessRule?.name,
                  },
                ];
                EventBus.emit(
                  EventBusEvents.ON_SECURITY_POLICY_OPTIONS_CHANGE,
                  {
                    fieldId: field.id,
                    options: [
                      {
                        id: permissions?.filegrantData?.accessRule?.id,
                        value: permissions?.filegrantData?.accessRule?.name,
                      },
                    ],
                  },
                );
              }
              if (field.id === "certificate") {
                field.options = [
                  {
                    id: permissions?.filegrantData?.certificate?.id,
                    value: permissions?.filegrantData?.certificate?.name,
                  },
                ];
                EventBus.emit(
                  EventBusEvents.ON_SECURITY_POLICY_OPTIONS_CHANGE,
                  {
                    fieldId: field.id,
                    options: [
                      {
                        id: permissions?.filegrantData?.certificate?.id,
                        value: permissions?.filegrantData?.certificate?.name,
                      },
                    ],
                  },
                );
              }
            });

            updatedSecurityPolicy.permissions = permissions;
          }
          break;
        case PolicyTypeEnum.SecureBrowser:
          {
            const permissions = updatedSecurityPolicy.permissions;
            permissions.password = permissions?.authentication?.password;
            permissions.authentication = permissions?.authentication?.type;
            updatedSecurityPolicy.permissions = permissions;
          }
          break;
        default:
          break;
      }

      // Parse flags for download file permission
      /*if (updatedSecurityPolicy.type === PolicyTypeEnum.downloadFile) {
        if (updatedSecurityPolicy.permissions.extensions.includes("*")) {
          updatedSecurityPolicy.permissions.allExtensions = true;
          delete updatedSecurityPolicy.permissions.extensions;
        }
      }*/
      setSecurityPolicy(updatedSecurityPolicy);
      setForm(updatedSecurityPolicy);
      setError("");
    } finally {
      setIsLoading(false);
    }
  }, [id, settings]);

  /**
   * @desc Fetches user data from the API when the id changes
   */
  useEffect(() => {
    if (id) {
      getSecurityPolicy().then(() => {});
    } else {
      resetForm();
      setIsLoading(false);
    }
  }, [getSecurityPolicy, id, resetForm]);

  /**
   * @desc Filters the policy based on the type
   */
  const policy = useMemo(() => {
    if (typeof form.type !== "undefined") {
      return policies.find((item) => item.type === form.type);
    } else {
      return null;
    }
  }, [form.type]);

  /**
   * @desc Checks if the form is in editing mode
   */
  const isEditing = useMemo(() => {
    return !!id;
  }, [id]);

  /**
   * Saves the serialized form object as a memoized value.
   * The form object is converted to a JSON string using JSON.stringify().
   * The memoized value is updated whenever the form object changes.
   *
   * @param {Object} form - The form object to be serialized.
   * @returns {string} - The serialized form object as a JSON string.
   */
  const formKey = useMemo(() => {
    return JSON.stringify(form);
  }, [form]);

  /**
   * Returns an array of policy severities based on the current policy and form permissions.
   *
   * If the policy type is PolicyTypeEnum.ExternalDrives and the form permissions mode is "0",
   * the resulted array will contain all policy severities except PolicySeverityEnum.info.
   *
   * If the policy type is PolicyTypeEnum.ExternalDrives and the form permissions mode is not "0",
   * the resulted array will only contain PolicySeverityEnum.info.
   *
   * For all other policy types, the resulted array will be the same as policySeverities.
   *
   * @returns {Array} The filtered array of policy severities.
   */
  const filteredSeverities: PolicySeverity[] = useMemo(() => {
    switch (policy?.type) {
      case PolicyTypeEnum.ExternalDrives:
      case PolicyTypeEnum.FileAccessApplication:
      case PolicyTypeEnum.UploadNewFileExe:
      case PolicyTypeEnum.DeleteFile:
        if (form?.permissions.mode?.toString() === "0") {
          return policySeverities.filter(
            (item) => item.id !== PolicySeverityEnum.info,
          );
        } else if (form?.permissions?.mode?.toString() === "1") {
          return policySeverities.filter(
            (item) => item.id === PolicySeverityEnum.info,
          );
        } else {
          return policySeverities.filter(
            (item) => item.id !== PolicySeverityEnum.info,
          );
        }
      default:
        return policySeverities.filter(
          (item) => item.id !== PolicySeverityEnum.info,
        );
    }
  }, [t, policy, form]);

  /**
   * @desc Resets the form when the right panel is closed
   */
  useEffect(() => {
    if (!isRightPanelOpen) {
      resetForm();
    }
  }, [isRightPanelOpen, resetForm]);

  // Disable due to allExtensions field commented on policyField
  /*useEffect(() => {
    const fieldsToDisable = [];
    if (form.permissions.allExtensions) {
      fieldsToDisable.push("extensions");
      setForm((prevForm) => {
        const newForm = { ...prevForm };
        delete newForm.permissions.extensions;
        return newForm;
      });
    }

    const arraysAreEqual = (arr1: string[], arr2: string[]) => {
      if (arr1.length !== arr2.length) return false;
      return arr1.every((value, index) => value === arr2[index]);
    };
    // Check if arrays are different
    if (!arraysAreEqual(fieldsToDisable, permissionDisabledFields)) {
      setPermissionDisabledFields(fieldsToDisable);
    }
  }, [form, permissionDisabledFields, setPermissionDisabledFields, setForm]);*/

  useEffect(() => {
    switch (policy?.type) {
      case PolicyTypeEnum.BlockPort: {
        const foundErrors: Record<string, string> = {};
        if (
          !form?.permissions ||
          !("ports" in form.permissions) ||
          form?.permissions?.ports?.length === 0
        ) {
          foundErrors.ports = t("validation.onePortPair");
        }
        if (
          !form?.permissions ||
          !("direction" in form.permissions) ||
          !form?.permissions?.direction
        ) {
          foundErrors.direction = t("validation.required", {
            field: "Direction",
          });
        }

        setPermissionErrors(foundErrors);
        break;
      }
      case PolicyTypeEnum.PortRDP:
        {
          const errors: Record<string, string> = {};
          if (!form?.permissions || !("port" in form.permissions)) {
            errors.port = t("validation.onePort");
          }
          setPermissionErrors(errors);
        }
        break;
      /*case PolicyTypeEnum.deleteFile:
        case PolicyTypeEnum.downloadFile:
          if (
            !form?.permissions?.allExtensions &&
            (!form.permissions ||
              !("extensions" in form.permissions) ||
              form?.permissions?.extensions?.length === 0)
          ) {
            setPermissionErrors({
              extensions: t("validation.leastOneExtension"),
            });
          }

        break;*/
      case PolicyTypeEnum.DeleteFile:
      case PolicyTypeEnum.UploadNewFileExe:
      case PolicyTypeEnum.FileAccessApplication: {
        const errors: Record<string, string> = {};
        let disabledFields: string[] = [];

        if (!form?.permissions || !("mode" in form.permissions)) {
          errors.mode = t("validation.required", {
            field: "Mode",
          });
        }

        if (!form.permissions.mode || form.permissions.mode === "0") {
          disabledFields.push(
            "confirm",
            "confirmMessage",
            "confirmCustomMessage",
          );
        } else {
          disabledFields = disabledFields.filter((item) =>
            ["confirm", "confirmMessage", "confirmCustomMessage"].includes(
              item,
            ),
          );
        }

        const hasExtensions = ["extensions", "customExtensions"].some((key) => {
          return (
            form.permissions &&
            form.permissions[key] &&
            form.permissions[key].length > 0
          );
        });

        // Check if at least one extension is selected
        if (!hasExtensions) {
          errors.customExtensions = t(
            "validation.leastOnePredefinedCustomExtension",
          );
        } else if (
          !form.permissions?.monitorSpecificFolders &&
          form.permissions?.customExtensions?.includes("*")
        ) {
          errors.customExtensions = t(
            "validation.leastOneFolderForExtensionsWildcard",
          );
        }

        // Check if at least one folder is selected
        if (form.permissions?.monitorSpecificFolders) {
          const hasFolders = ["folders", "customFolders"].some((key) => {
            return (
              form.permissions &&
              form.permissions[key] &&
              form.permissions[key].length > 0
            );
          });

          if (!hasFolders) {
            errors.customFolders = t(
              "validation.leastOnePredefinedCustomFolder",
            );
          }
          // Remove folders and customFolders from disabled fields
          disabledFields = disabledFields.filter(
            (item) => item !== "folders" && item !== "customFolders",
          );
        } else {
          disabledFields.push("folders", "customFolders");
          if (
            form?.permissions?.customFolders?.length !== 0 ||
            form?.permissions?.folders?.length !== 0
          ) {
            setForm((prevForm) => ({
              ...prevForm,
              permissions: {
                ...prevForm.permissions,
                customFolders: [],
                folders: [],
              },
            }));
          }
        }

        // Check confirm field
        if (!form?.permissions?.confirm) {
          disabledFields.push("confirmCustomMessage", "confirmMessage");

          if (
            form?.permissions?.confirmMessage ||
            form?.permissions?.confirmCustomMessage
          ) {
            setForm((prevForm) => ({
              ...prevForm,
              permissions: {
                ...prevForm.permissions,
                confirmCustomMessage: false,
                confirmMessage: "",
              },
            }));
          }
        } else {
          // Remove confirmCustomMessage from disabled fields
          disabledFields = disabledFields.filter(
            (item) => !["confirmCustomMessage"].includes(item),
          );
          if (form?.permissions?.confirmCustomMessage) {
            disabledFields = disabledFields.filter(
              (item) => !["confirmMessage"].includes(item),
            );
          } else {
            disabledFields.push("confirmMessage");
            if (form.permissions.confirmMessage !== "") {
              setForm((prevForm) => ({
                ...prevForm,
                permissions: {
                  ...prevForm.permissions,
                  confirmMessage: "",
                },
              }));
            }
          }
        }

        // Remove exceptInclude and exceptExclude from that are not included in certificates
        if (form.permissions?.certificates?.length === 0) {
          disabledFields.push("exceptInclude", "exceptExclude");
          if (
            form.permissions.exceptInclude.length > 0 ||
            form.permissions.exceptExclude.length > 0
          ) {
            setForm((prevForm) => ({
              ...prevForm,
              permissions: {
                ...prevForm.permissions,
                exceptExclude: [],
                exceptInclude: [],
              },
            }));
          }
        } else {
          const currentExceptInclude = form.permissions?.exceptInclude;
          const currentExceptExclude = form.permissions?.exceptExclude;

          const mappedCertificates = form.permissions?.certificates?.map(
            (certificate: { id: string }) => certificate.id,
          );

          // Convert mappedCertificates from strings to numbers to make comparison easier
          const validIds = new Set(mappedCertificates?.map(Number));

          // Filter exceptInclude to keep only objects with item1 included in validIds
          const filteredExceptInclude = currentExceptInclude?.filter(
            (obj: { item1: string }) => validIds.has(Number(obj.item1)),
          );

          // Filter exceptExclude with the same logic as exceptInclude
          const filteredExceptExclude = currentExceptExclude?.filter(
            (obj: { item1: string }) => validIds.has(Number(obj.item1)),
          );

          // Check if the length of the arrays has changed
          const isIncludeChanged =
            currentExceptInclude?.length !== filteredExceptInclude?.length;
          const isExcludeChanged =
            currentExceptExclude?.length !== filteredExceptExclude?.length;

          if (isIncludeChanged || isExcludeChanged) {
            setForm((prevForm) => ({
              ...prevForm,
              permissions: {
                ...prevForm.permissions,
                exceptExclude: filteredExceptExclude,
                exceptInclude: filteredExceptInclude,
              },
            }));
          }
        }

        setPermissionErrors(errors);
        setPermissionDisabledFields(disabledFields);
        break;
      }
      case PolicyTypeEnum.Network:
        {
          const errors: Record<string, string> = {};

          if (
            !form.permissions ||
            !("domains" in form.permissions) ||
            form?.permissions?.domains?.length === 0
          ) {
            errors.domains = t("validation.leastOneDomain");
          }
          setPermissionErrors(errors);
        }
        break;
      case PolicyTypeEnum.Obfuscator: {
        const errors: Record<string, string> = {};
        let disabledFields: string[] = [];

        const hasData = [
          "complete",
          "email",
          "phone",
          "vat",
          "creditCard",
          "taxCode",
          "socialSecurityNumber",
        ].some((key) => {
          return form.permissions && form.permissions[key];
        });

        if (!hasData) {
          errors.socialSecurityNumber = t("validation.leastOneElementChecked");
        }

        if (form.permissions?.complete) {
          disabledFields = [
            "email",
            "phone",
            "vat",
            "creditCard",
            "taxCode",
            "socialSecurityNumber",
          ];
        }

        setPermissionErrors(errors);
        setPermissionDisabledFields(disabledFields);
        break;
      }
      case PolicyTypeEnum.Country:
        if (
          !form?.permissions ||
          !("values" in form.permissions) ||
          form?.permissions?.values?.length === 0
        ) {
          setPermissionErrors({
            values: t("validation.leastOneCountry"),
          });
        } else {
          setPermissionErrors({});
        }
        break;
      case PolicyTypeEnum.DateTimePlan:
        {
          const errors: Record<string, string> = {};

          if (
            !form?.permissions ||
            !("days" in form.permissions) ||
            form?.permissions?.days?.length === 0
          ) {
            errors.times = t("validation.leastOneDaySelected");
          }

          setPermissionErrors(errors);
        }
        break;
      case PolicyTypeEnum.Expiry:
        if (!form.permissions || !("value" in form.permissions)) {
          setPermissionErrors({
            value: t("validation.date"),
          });
        } else {
          setPermissionErrors({});
        }
        break;
      case PolicyTypeEnum.RangeIPAddress:
        if (
          !form.permissions ||
          !("values" in form.permissions) ||
          form?.permissions?.values?.length === 0
        ) {
          setPermissionErrors({
            values: t("validation.leastOneIpAddress"),
          });
        } else {
          setPermissionErrors({});
        }
        break;
      case PolicyTypeEnum.WindowsAccess:
        if (
          !form.permissions ||
          !("username" in form.permissions) ||
          form?.permissions?.username?.length === 0
        ) {
          setPermissionErrors({
            username: t("validation.leastOneUsername"),
          });
        } else {
          setPermissionErrors({});
        }
        break;
      case PolicyTypeEnum.ExternalDrives:
        {
          const errors: Record<string, string> = {};
          let disabledFields: string[] = [];

          if (!form?.permissions || !("mode" in form.permissions)) {
            errors.mode = t("validation.required", {
              field: "Mode",
            });
          }

          if (!form.permissions.mode || form.permissions.mode === "0") {
            disabledFields.push("confirm");
          } else {
            disabledFields = disabledFields.filter(
              (item) => item !== "confirm",
            );
          }

          if (!form?.permissions?.confirm) {
            disabledFields.push("confirmCustomMessage", "confirmMessage");

            if (
              form?.permissions?.confirmMessage ||
              form?.permissions?.confirmCustomMessage
            ) {
              setForm((prevForm) => ({
                ...prevForm,
                permissions: {
                  ...prevForm.permissions,
                  confirmCustomMessage: false,
                  confirmMessage: "",
                },
              }));
            }
          } else {
            // Remove confirmCustomMessage from disabled fields
            disabledFields = disabledFields.filter(
              (item) => !["confirmCustomMessage"].includes(item),
            );
            if (form?.permissions?.confirmCustomMessage) {
              disabledFields = disabledFields.filter(
                (item) => !["confirmMessage"].includes(item),
              );
            } else {
              disabledFields.push("confirmMessage");
              if (form.permissions.confirmMessage !== "") {
                setForm((prevForm) => ({
                  ...prevForm,
                  permissions: {
                    ...prevForm.permissions,
                    confirmMessage: "",
                  },
                }));
              }
            }
          }

          setPermissionErrors(errors);
          setPermissionDisabledFields(disabledFields);
        }
        break;
      case PolicyTypeEnum.AccessFolders: {
        const errors: Record<string, string> = {};
        const disabledFields: string[] = [];

        const hasPaths = ["folders", "absolutePaths"].some((key) => {
          return (
            form.permissions &&
            form.permissions[key] &&
            form.permissions[key].length > 0
          );
        });

        // Check if at least one extension is selected
        if (!hasPaths) {
          errors.absolutePaths = t("validation.leastOneSpecialFoldersOrPaths");
        }

        // Check mode access code
        if (!("modeAccessCode" in form.permissions)) {
          setForm((prevForm) => ({
            ...prevForm,
            permissions: {
              ...prevForm.permissions,
              modeAccessCode: "0",
            },
          }));
        } else if (form?.permissions?.modeAccessCode.toString() === "0") {
          disabledFields.push("accessCode");
          if (form?.permissions?.accessCode !== "") {
            // Set access code to empty
            setForm((prevForm) => ({
              ...prevForm,
              permissions: {
                ...prevForm.permissions,
                accessCode: "",
              },
            }));
          }
        } else if (
          form?.permissions?.modeAccessCode.toString() === "1" &&
          form?.permissions?.accessCode.trim() === ""
        ) {
          errors.accessCode = t("validation.required", {
            field: "Access code",
          });
        }

        // Check timespan field
        if (!("timeout" in form.permissions)) {
          errors.timeout = t("validation.required", {
            field: "Auto lock option",
          });
        }

        setPermissionErrors(errors);
        setPermissionDisabledFields(disabledFields);
        break;
      }
      case PolicyTypeEnum.ProxyControl: {
        const errors: Record<string, string> = {};
        let disabledFields: string[] = [];

        if (
          form?.permissions?.setProxy &&
          (!form.permissions?.address?.trim() || !form.permissions?.port)
        ) {
          errors.port = t("validation.addressAndPort");
        }

        // Validate duration required
        if (
          form.permissions.allowUnlock &&
          form.permissions.unlockTimeout === null
        ) {
          errors.unlockTimeout = t("validation.required", {
            field: "Duration",
          });
        }

        if (!form?.permissions?.lockSettings) {
          disabledFields.push("notification");
          if (form.severity || form.permissions.notification) {
            setForm((prevForm) => ({
              ...prevForm,
              permissions: {
                ...prevForm.permissions,
                notification: false,
              },
              severity: undefined,
            }));
          }
        }

        if (!form?.permissions?.setProxy) {
          disabledFields.push("address", "port");
          if (form.permissions.address || form.permissions.port) {
            setForm((prevForm) => ({
              ...prevForm,
              permissions: {
                ...prevForm.permissions,
                address: "",
                port: "",
              },
            }));
          }
        }

        // Check confirm field
        if (!form?.permissions?.lockSettings) {
          disabledFields.push("allowUnlock", "unlockTimeout");

          if (
            form?.permissions?.unlockTimeout ||
            form?.permissions?.allowUnlock
          ) {
            setForm((prevForm) => ({
              ...prevForm,
              permissions: {
                ...prevForm.permissions,
                allowUnlock: false,
                unlockTimeout: null,
              },
            }));
          }
        } else {
          // Remove confirmCustomMessage from disabled fields
          disabledFields = disabledFields.filter(
            (item) => !["allowUnlock"].includes(item),
          );
          if (form?.permissions?.allowUnlock) {
            disabledFields = disabledFields.filter(
              (item) => !["unlockTimeout"].includes(item),
            );
          } else {
            disabledFields.push("unlockTimeout");
            if (form.permissions.unlockTimeout !== null) {
              setForm((prevForm) => ({
                ...prevForm,
                permissions: {
                  ...prevForm.permissions,
                  unlockTimeout: null,
                },
              }));
            }
          }
        }

        setPermissionErrors(errors);
        setPermissionDisabledFields(disabledFields);
        break;
      }
      case PolicyTypeEnum.EncryptionFile: {
        const errors: Record<string, string> = {};
        let disabledFields: string[] = [];

        const hasExtensions = ["extensions", "customExtensions"].some((key) => {
          return (
            form.permissions &&
            form.permissions[key] &&
            form.permissions[key].length > 0
          );
        });

        // Check if at least one extension is selected
        if (!hasExtensions) {
          errors.customExtensions = t(
            "validation.leastOnePredefinedCustomExtension",
          );
        } else if (
          !form.permissions?.monitorSpecificFolders &&
          form.permissions?.customExtensions?.includes("*")
        ) {
          errors.customExtensions = t(
            "validation.leastOneFolderForExtensionsWildcard",
          );
        }

        // Check if at least one folder is selected
        if (form.permissions?.monitorSpecificFolders) {
          const hasFolders = ["folders", "customFolders"].some((key) => {
            return (
              form.permissions &&
              form.permissions[key] &&
              form.permissions[key].length > 0
            );
          });

          if (!hasFolders) {
            errors.customFolders = t(
              "validation.leastOnePredefinedCustomFolder",
            );
          }
          // Remove folders and customFolders from disabled fields
          disabledFields = disabledFields.filter(
            (item) => item !== "folders" && item !== "customFolders",
          );
        } else {
          disabledFields.push("folders", "customFolders");
          if (
            form?.permissions?.customFolders?.length !== 0 ||
            form?.permissions?.folders?.length !== 0
          ) {
            setForm((prevForm) => ({
              ...prevForm,
              permissions: {
                ...prevForm.permissions,
                customFolders: [],
                folders: [],
              },
            }));
          }
        }

        if (!form.permissions.apiKey || form.permissions.apiKey === "") {
          errors.apiKey = t("validation.required", {
            field: "API Key",
          });
        }

        if (
          !form.permissions.certificate ||
          form.permissions?.certificate === ""
        ) {
          errors.certificate = t("validation.required", {
            field: "Certificate",
          });
        }

        if (
          !form.permissions.accessRule ||
          form.permissions?.accessRule === ""
        ) {
          errors.accessRule = t("validation.required", {
            field: "Access Rule",
          });
        }

        if (isEditing) {
          disabledFields.push("apiKey", "certificate", "accessRule");
        }

        setPermissionErrors(errors);
        setPermissionDisabledFields(disabledFields);
        break;
      }
      case PolicyTypeEnum.AntiPhishing:
        if (!form.permissions || !("block" in form.permissions)) {
          setPermissionErrors({
            block: t("validation.required", {
              field: "Score",
            }),
          });
        } else {
          setPermissionErrors({});
        }
        break;

      case PolicyTypeEnum.SecureBrowser:
        {
          const errors: Record<string, string> = {};
          const disabledFields: string[] = [];
          if (
            form?.permissions?.denyUpload &&
            !form?.permissions?.denyDownload
          ) {
            setForm((prevForm) => ({
              ...prevForm,
              permissions: {
                ...prevForm.permissions,
                denyDownload: true,
              },
            }));
          }

          if (form?.permissions?.denyUpload) {
            disabledFields.push("denyDownload");
          }

          if (!form.permissions?.clipboard) {
            setForm((prevForm) => ({
              ...prevForm,
              permissions: {
                ...prevForm.permissions,
                clipboard: "0",
              },
            }));
          }

          if (!form.permissions?.authentication) {
            setForm((prevForm) => ({
              ...prevForm,
              permissions: {
                ...prevForm.permissions,
                authentication: "0",
              },
            }));
          }

          if (!form.permissions?.timeoutClosing) {
            errors.timeoutClosing = t("validation.required", {
              field: "Auto close browser",
            });
          }

          if (
            form.permissions?.authentication === "1" &&
            !form.permissions?.password
          ) {
            errors.password = t("validation.required", {
              field: "Access code",
            });
          } else if (
            form.permissions?.authentication === "0" &&
            form?.permissions?.password
          ) {
            disabledFields.push("password");
            setForm((prevForm) => ({
              ...prevForm,
              permissions: {
                ...prevForm.permissions,
                password: "",
              },
            }));
          } else if (form.permissions?.authentication === "0") {
            disabledFields.push("password");
          }

          setPermissionErrors(errors);
          setPermissionDisabledFields(disabledFields);
        }
        break;

      default:
        setPermissionErrors({});
    }
  }, [
    form,
    setForm,
    setPermissionErrors,
    setPermissionDisabledFields,
    policy,
    t,
  ]);

  /**
   * @desc Validates the form
   */
  useEffect(() => {
    setFormErrors({});
    if (!form.name?.trim()) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        name: t("validation.required", { field: "Name" }),
      }));
    }
    if (
      policy?.hasNotification &&
      form.permissions.notification &&
      !form.severity
    ) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        severity: t("validation.required", { field: "Severity" }),
      }));
    }
  }, [form.name, form.permissions.notification, form.severity, form.type, t]);

  /**
   * @desc Change form on permission change
   */
  useEffect(() => {
    if (!form.permissions.notification) {
      setForm((prevForm) => ({
        ...prevForm,
        severity: undefined,
      }));
    }
    if (!form.permissions.mode || form.permissions?.mode?.toString() === "0") {
      if (
        form.permissions.notification &&
        form.severity === PolicySeverityEnum.info
      ) {
        setForm((prevForm) => ({
          ...prevForm,
          permissions: {
            ...prevForm.permissions,
            confirm: false,
          },
          severity: PolicySeverityEnum.low,
        }));
      } else {
        setForm((prevForm) => ({
          ...prevForm,
          permissions: {
            ...prevForm.permissions,
            confirm: false,
          },
        }));
      }
    } else {
      if (form.severity !== PolicySeverityEnum.info) {
        setForm((prevForm) => ({
          ...prevForm,
          permissions: {
            ...prevForm.permissions,
            notification: true,
          },
          severity: PolicySeverityEnum.info,
        }));
      }
    }
  }, [form.permissions.notification, form.permissions.mode, form.severity]);

  useEffect(() => {
    // Fetch only in create mode
    if (
      isEditing ||
      form?.type === null ||
      form?.type !== PolicyTypeEnum.EncryptionFile
    ) {
      return;
    }

    const timeoutId = setTimeout(() => {
      fetchFilegrantData().then(() => {});
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [form.permissions.apiKey]);

  /**
   * Represents an array of invalid fields.
   *
   * @typedef {Array<string>} InvalidFields
   */
  const invalidFields = useMemo(() => {
    const fields: string[] = [];
    // Check regex for each field of selected policy
    if (policy?.type) {
      policyFields[policy.type]?.map((field) => {
        if (field.pattern && form.permissions[field.id]) {
          const formField = form.permissions[field.id];
          switch (field.inputType) {
            case "comboboxstringtuple": {
              formField.map((item: any, index: number): void => {
                if (
                  !field?.pattern?.test(item?.item2) ||
                  item?.item2?.trim() === ""
                ) {
                  fields.push(`${field.id}-item2-${index}`);
                }
              });
              break;
            }
            case "stringlist": {
              formField.map((item: string, index: number): void => {
                if (!field?.pattern?.test(item)) {
                  fields.push(`${field.id}-${index}`);
                }
              });
              break;
            }
            default:
              break;
          }
        }
      });
    }
    return fields;
  }, [policy?.type, form]);

  return {
    error,
    filteredSeverities,
    form,
    formErrors,
    formKey,
    formRef,
    handleCancel,
    handleInputAction,
    handleInputChange,
    handlePermissionsEdit,
    invalidFields,
    isCurrentUserReadOnly,
    isEditing,
    isLoading,
    onSubmit,
    permissionDisabledFields,
    permissionErrors,
    permissionLoadingFields,
    policy,
    securityPolicy,
    setForm,
    t,
  };
}

export default useSecurityPolicyDetail;
