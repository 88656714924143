import { SortDescriptor } from "@react-types/shared";
import qs from "qs";
import { Key, useCallback, useEffect, useMemo, useState } from "react";
import { useDateFormatter } from "react-aria";
import { useTranslation } from "react-i18next";
import { useOverlayTriggerState } from "react-stately";

import { TableAction } from "../components/TableActionsBar";
import { TreeItem } from "../components/Tree";
import { useDialogContext } from "../contexts/DialogContext";
import { NotificationTypes } from "../contexts/NotificationContext";
import { StorageItemKeyEnum } from "../enum/StorageItemKeyEnum";
import { UserActivitiesGroupingCriteriaEnum } from "../enum/UserActivitiesGroupingCriteriaEnum";
import { UserActivityTypeEnum } from "../enum/UserActivityTypeEnum";
import Computer from "../interfaces/Computer";
import PolicySeverity from "../interfaces/PolicySeverity";
import { TimespanOptionKey } from "../interfaces/TimespanOption";
import User from "../interfaces/User";
import UserActivityType from "../interfaces/UserActivityType";
import { ActivityService } from "../services/activityService";
import { SecurityPolicyService } from "../services/securityPolicyService";
import { getStorageItem, storeStorageItem } from "../utils/storage";
import { userActivitiesGroupedPoliciesTreeItems } from "../utils/userActivitiesGroupedPoliciesTreeItems";
import {
  convertDateToTicks,
  findDeepestTreeItemOpenNode,
  findUserActivityByType,
  generateUUID,
  getTimestampOptionDates,
  transformObjectArraysToArrayOfIds,
} from "../utils/utils";

import { useNotifications } from "./useNotifications";

const activityService = new ActivityService();
const securityPolicyService = new SecurityPolicyService();

interface FilterState {
  activityType?: UserActivityType[];
  computerID?: Computer[];
  count: number;
  dateEnd: number;
  dateStart: number;
  offset: number;
  search?: string;
  userID?: User[];
  sort?: SortDescriptor;
  severity?: PolicySeverity[];
  timespanOption: TimespanOptionKey;
  notRead: boolean;
  hidden?: boolean;
}

interface Props {
  initialFilters: FilterState;
}

function useGroupedUserActivitiesTab({ initialFilters }: Props) {
  const [groupedTotal, setGroupedTotal] = useState<number>(0);
  const [isLoadingTree, setIsLoadingTree] = useState<boolean>(true);
  const [isLoadingTreeItem, setIsLoadingTreeItem] = useState<boolean>(true);
  const [treeItems, setTreeItems] = useState<TreeItem[]>([]);
  const [selectedGroupedItem, setSelectedGroupedItem] = useState<any>(null);
  const [isMounted, setIsMounted] = useState(false);
  const [groupingCriteria, setGroupingCriteria] =
    useState<UserActivitiesGroupingCriteriaEnum>(() => {
      const savedState = getStorageItem(
        "sessionStorage",
        StorageItemKeyEnum.userActivitiesGroupingCriteria,
      );
      return savedState !== null
        ? (savedState as UserActivitiesGroupingCriteriaEnum)
        : UserActivitiesGroupingCriteriaEnum.policy;
    });

  const [groupedFilters, setGroupedFilters] =
    useState<FilterState>(initialFilters);

  const groupedActionModalState = useOverlayTriggerState({});
  const { t } = useTranslation();
  const { createNotification } = useNotifications();
  const dialog = useDialogContext();
  const dateFormatter = useDateFormatter({
    dateStyle: "medium",
    timeStyle: "short",
  });

  /**
   * Returns a string representation of the filters object by performing several transformations and using query string parameters.
   *
   * @returns {string} The stringified filters object
   */
  function getStringifiesFilters(): string {
    const updatedFilters = structuredClone(groupedFilters);
    if (groupedFilters.timespanOption !== TimespanOptionKey.Custom) {
      const { from, to } = getTimestampOptionDates(
        groupedFilters.timespanOption,
      );
      updatedFilters.dateEnd = convertDateToTicks(to);
      updatedFilters.dateStart = convertDateToTicks(from);
    }

    if (groupingCriteria === UserActivitiesGroupingCriteriaEnum.hiddenPolicy) {
      updatedFilters.hidden = true;
    }

    const transformedFilters =
      transformObjectArraysToArrayOfIds(updatedFilters);

    if (transformedFilters.sort !== undefined) {
      transformedFilters.sortCol = groupedFilters.sort?.column;
      transformedFilters.sortDir = groupedFilters.sort?.direction;
    } else {
      transformedFilters.sortCol = "createdOn";
      transformedFilters.sortDir = "descending";
    }

    return qs.stringify(transformedFilters, {
      filter: (prefix, value) => (value === "" ? undefined : value),
      skipNulls: true,
    });
  }

  /**
   * Recursively searches for a tree item with a given key.
   *
   * @param {TreeItem[]} items - The array of tree items to search in.
   * @param {string} key - The key of the tree item to find.
   * @return {TreeItem | undefined} - The found tree item or undefined if not found.
   */
  function findTreeItemRecursively(
    items: TreeItem[],
    key: string,
  ): TreeItem | undefined {
    for (const item of items) {
      if (item.key === key) {
        return item;
      } else if (item.children) {
        const found = findTreeItemRecursively(item.children, key);
        if (found) {
          return found;
        }
      }
    }
    return undefined;
  }

  /**
   * Fetches user activities grouped by policy from the server.
   *
   * @async
   * @function getGroupedPolicyLevel0
   * @returns {Promise<void>} - Promise that resolves to undefined.
   */
  const getGroupedPolicyLevel0 = useCallback(async () => {
    setIsLoadingTree(true);
    try {
      const res = await activityService.getUserActivitiesGroupedByPolicy(
        getStringifiesFilters(),
      );
      setGroupedTotal(0);
      const newTreeItems: TreeItem[] = res.map((item: any) => {
        const foundUserActivityByType = findUserActivityByType(item.type);

        return {
          children: [
            {
              isOpen: false,
              key: "empty",
              title: `Loading ${t("common.activities")}...`,
            },
          ],
          data: {
            level: 0,
            type: "policy",
            userActivityType: item.type,
            value: item,
          },
          isOpen: false,
          key: item.type,
          title: foundUserActivityByType?.policy?.name || item.type,
        };
      });

      setTreeItems(newTreeItems);
    } catch (error) {
      createNotification(
        t("errors.genericFetchError", {
          resource: t("common.activities"),
        }),
        NotificationTypes.DANGER,
      );
    } finally {
      setIsLoadingTree(false);
    }
  }, [createNotification, t, groupedFilters, groupingCriteria]);

  /**
   * Retrieves user activities by policy type.
   *
   * @param {string} type - The policy type.
   * @returns {Promise<void>} - A Promise that resolves once the user activities are retrieved.
   */
  const getGroupedPolicyLevel1 = async (type: string): Promise<void> => {
    setIsLoadingTreeItem(true);
    const result = await activityService.getUserActivitiesByPolicyType(
      type,
      getStringifiesFilters(),
    );
    setTreeItems((prevTreeItems) => {
      return prevTreeItems.map((item) => {
        if (item.key === type) {
          return {
            ...item,
            children: result.map((activity: any) => {
              let hasActionsEnabled = true;
              if (activity?.policy?.createdOn) {
                const currentPolicyDate = new Date(activity?.policy?.createdOn);
                const startDate2024 = new Date("2024-01-01T00:00:00");
                hasActionsEnabled =
                  currentPolicyDate.getTime() > startDate2024.getTime();
              }

              let title = activity?.policy?.name || "N/D";

              if (!hasActionsEnabled) {
                title = `${title} (${t("common.deleted")})`;
              }

              return {
                children: [
                  {
                    isOpen: false,
                    key: "empty",
                    title: `Loading ${t("common.activities")}...`,
                  },
                ],
                data: {
                  hasActionsEnabled: hasActionsEnabled,
                  level: 1,
                  parentKey: item.key,
                  type: "policy",
                  userActivityType: type,
                  value: activity,
                },
                isOpen: false,
                key: activity?.policy?.id || "N/D",
                title: title,
              };
            }),
          };
        }
        return item;
      });
    });
    setIsLoadingTreeItem(false);
  };

  /**
   * Gets user activities by policy type and policy.
   *
   * @param id
   * @param {string} type - The policy type.
   * @returns {Promise<void>} - A Promise that resolves once the user activities are retrieved.
   */
  const getGroupedPolicyLevel2 = async (
    id: string,
    type: string,
  ): Promise<void> => {
    setIsLoadingTreeItem(true);
    const result = await activityService.getUserActivitiesByPolicyTypeAndPolicy(
      id,
      type,
      getStringifiesFilters(),
    );

    console.table({ id, type });
    setGroupedTotal(result.total);
    setTreeItems((prevTreeItems) => {
      const itemToUpdate = findTreeItemRecursively(prevTreeItems, id);

      if (!itemToUpdate) {
        return prevTreeItems;
      }

      const updateTreeItems = (items: TreeItem[]): TreeItem[] => {
        return items.map((item) => {
          if (item.key === id) {
            return {
              ...item,
              children: result.data.map((activity: any) => {
                const uuid = generateUUID();
                let title: string =
                  activity?.url || activity?.computer?.name || "N/D";

                if (activity?.application) {
                  const parts = activity.application.split("\\");
                  title = parts[parts.length - 1];
                }

                const itemValue = item?.data?.value as {
                  policy?: { createdOn?: string };
                };

                let hasActionsEnabled = true;
                if (itemValue?.policy?.createdOn) {
                  const currentPolicyDate = new Date(
                    itemValue?.policy?.createdOn,
                  );
                  const startDate2024 = new Date("2024-01-01T00:00:00");
                  hasActionsEnabled =
                    currentPolicyDate.getTime() > startDate2024.getTime();
                }

                return {
                  data: {
                    hasActionsEnabled: hasActionsEnabled,
                    isNew: activity.isNew || false,
                    level: 2,
                    parentKey: item.key,
                    parentTitle: item.title,
                    policyId: id,
                    showChildren: false,
                    type: "policy",
                    userActivityType: type,
                    value: activity,
                  },
                  isOpen: false,
                  key: uuid,
                  title: title,
                };
              }),
            };
          } else if (item.children) {
            return { ...item, children: updateTreeItems(item.children) };
          }
          return item;
        });
      };

      return updateTreeItems(prevTreeItems);
    });

    setIsLoadingTreeItem(false);
  };

  /**
   * Gets user activities by policy type and policy and entity.
   *
   * @param id
   * @param {string} type - The policy type.
   * @param entity
   * @param key
   * @returns {Promise<void>} - A Promise that resolves once the user activities are retrieved.
   */
  const getGroupedPolicyLevel3 = async (
    id: string,
    type: string,
    entity: string,
    key: string,
  ): Promise<void> => {
    console.table({ id, type });
    console.log(entity);
    console.log(key);

    setIsLoadingTreeItem(true);
    const result =
      await activityService.getUserActivitiesByPolicyTypeAndPolicyAndEntity(
        id,
        type,
        entity,
        getStringifiesFilters(),
      );

    setGroupedTotal(result.total);
    setTreeItems((prevTreeItems) => {
      const itemToUpdate = findTreeItemRecursively(prevTreeItems, id);
      if (!itemToUpdate) {
        return prevTreeItems;
      }
      const updateTreeItems = (items: TreeItem[]): TreeItem[] => {
        return items.map((item) => {
          if (item?.key === key) {
            return {
              ...item,
              children: result.data.map((activity: any) => ({
                data: {
                  level: 3,
                  parentKey: item.key,
                  type: "policy",
                  userActivityType: type,
                  value: activity,
                },
                isOpen: false,
                key: generateUUID(),
                showChildren: false,
                title: activity?.computer?.name || "N/D",
              })),
              showChildren: false,
            };
          } else if (item.children) {
            return {
              ...item,
              children: updateTreeItems(item.children),
            };
          }
          return item;
        });
      };

      return updateTreeItems(prevTreeItems);
    });
    setIsLoadingTreeItem(false);
  };

  /**
   * Fetches open node elements based on the grouping criteria.
   *
   * @returns {void}
   */
  const fetchOpenNodeElements = (): void => {
    const openNode = findDeepestTreeItemOpenNode(treeItems);

    if (
      !openNode ||
      !openNode?.node?.key ||
      !openNode?.node?.data?.userActivityType
    ) {
      switch (groupingCriteria) {
        case UserActivitiesGroupingCriteriaEnum.policy:
        case UserActivitiesGroupingCriteriaEnum.hiddenPolicy:
          if (!isMounted) {
            return;
          }
          getGroupedPolicyLevel0().then(() => {});
          return;

        default:
          return;
      }
    }

    switch (groupingCriteria) {
      case UserActivitiesGroupingCriteriaEnum.policy:
      case UserActivitiesGroupingCriteriaEnum.hiddenPolicy:
        switch (openNode?.depth) {
          case 1:
            getGroupedPolicyLevel2(
              openNode?.node?.key,
              openNode?.node?.data?.userActivityType,
            ).then(() => {});
            break;
          case 2: {
            switch (openNode?.node?.data?.userActivityType.toString()) {
              case "1000":
              case "1003":
              case "1008":
                {
                  const itemValue = openNode?.node?.data.value as {
                    applicationDetail?: { hash?: string };
                  };

                  if (itemValue?.applicationDetail?.hash) {
                    getGroupedPolicyLevel3(
                      openNode?.node?.data.policyId,
                      openNode?.node?.data.userActivityType,
                      itemValue?.applicationDetail?.hash,
                      openNode?.node?.key,
                    ).then(() => {});
                  }
                }
                break;
              case "1002":
                {
                  const itemValue = openNode?.node?.data.value as {
                    url?: string;
                  };
                  if (itemValue?.url) {
                    getGroupedPolicyLevel3(
                      openNode?.node?.data.policyId,
                      openNode?.node?.data.userActivityType,
                      btoa(itemValue.url),
                      openNode?.node?.key,
                    ).then(() => {});
                  }
                }
                break;
              default: {
                const itemValue = openNode?.node?.data.value as {
                  computer?: { id?: string };
                };
                if (itemValue.computer?.id) {
                  getGroupedPolicyLevel3(
                    openNode?.node?.data.policyId,
                    openNode?.node?.data.userActivityType,
                    itemValue.computer.id,
                    openNode?.node?.key,
                  ).then(() => {});
                }
              }
            }
            break;
          }
          default:
            break;
        }
        break;
      default:
        break;
    }
  };

  /**
   * Handles the action of a grouped table row.
   *
   * @param {Key} key - The key of the row to handle.
   * @returns {void}
   */
  const handleGroupedTableRowAction = (key: Key): void => {
    // Find the item in the tree
    const foundItem = findTreeItemRecursively(treeItems, key as string);

    if (!foundItem || foundItem?.data?.level.toString() === "3") {
      return;
    }

    handleTreeItemToggle(foundItem);
  };

  /**
   * Handles the toggle event of a tree item.
   *
   * @returns {void}
   * @param item
   * @param isOpen
   */
  const handleTreeItemToggle = (item: TreeItem, isOpen?: boolean): void => {
    const closeAllChildren = (node: TreeItem): TreeItem => ({
      ...node,
      children: node.children ? node.children.map(closeAllChildren) : undefined,
      isOpen: false,
    });
    const updateTreeItems = (
      treeItems: TreeItem[],
      itemKey: string,
    ): TreeItem[] => {
      let found = false;

      const updatedTreeItems = treeItems.map((treeItem) => {
        // If we found the node, we open it and close all its children
        if (treeItem.key === itemKey) {
          found = true;
          return {
            ...treeItem,
            children: treeItem.isOpen
              ? treeItem.children?.map(closeAllChildren)
              : treeItem.children,
            data: {
              ...treeItem.data,
              isNew: treeItem.data?.isNew
                ? false
                : treeItem.data?.isNew || false,
            },
            isOpen: isOpen ? isOpen : !treeItem.isOpen,
          };
        }
        // Close all nodes at the same level of the found node
        if (found) {
          return closeAllChildren(treeItem);
        }
        // If the node has children, we update them recursively
        if (treeItem.children) {
          const updatedChildren = updateTreeItems(treeItem.children, itemKey);
          return { ...treeItem, children: updatedChildren };
        }

        return treeItem;
      });
      // If the node was found and opened, we need to make sure all other nodes at the same level are closed
      if (found) {
        return updatedTreeItems.map((treeItem) =>
          treeItem.key !== itemKey ? closeAllChildren(treeItem) : treeItem,
        );
      }

      return updatedTreeItems;
    };

    setTreeItems((prevTreeItems) => updateTreeItems(prevTreeItems, item.key));

    // Reset offset in pagination and return because its watched by useEffect so it will trigger a new fetch and avoid double fetch
    if (groupedFilters.offset > 0) {
      setGroupedFilters((prevFilters) => ({ ...prevFilters, offset: 0 }));
      return;
    }

    if (isOpen || !item.isOpen) {
      if (item.data?.type === "policy") {
        switch (item.data.level.toString()) {
          case "0":
            getGroupedPolicyLevel1(item.key).then(() => {});
            break;
          case "1":
            getGroupedPolicyLevel2(item.key, item?.data?.userActivityType).then(
              () => {},
            );
            break;
          case "2":
            switch (item?.data?.userActivityType.toString()) {
              case "1000":
              case "1003":
              case "1008":
                {
                  const value1 = item.data.value as {
                    applicationDetail?: { hash?: string };
                  };
                  if (value1.applicationDetail?.hash) {
                    getGroupedPolicyLevel3(
                      item.data.policyId,
                      item.data.userActivityType,
                      value1.applicationDetail.hash,
                      item.key,
                    ).then(() => {});
                  }
                }
                break;
              case "1002":
                {
                  const value2 = item.data.value as { hash?: string };
                  if (value2.hash) {
                    getGroupedPolicyLevel3(
                      item.data.policyId,
                      item.data.userActivityType,
                      value2.hash,
                      item.key,
                    ).then(() => {});
                  }
                }
                break;
              default: {
                const valueDefault = item.data.value as {
                  computer?: { id?: string };
                };
                if (valueDefault.computer?.id) {
                  getGroupedPolicyLevel3(
                    item.data.policyId,
                    item.data.userActivityType,
                    valueDefault.computer.id,
                    item.key,
                  ).then(() => {});
                }
              }
            }

            break;
          default:
            break;
        }
      }
    }
  };

  /**
   * Handles the click event on a detail element.
   *
   * @param {UserActivity} element - The user activity element clicked.
   *
   * @param key
   * @returns {void}
   */
  const handleGroupedItemMenuAction = (element: TreeItem, key: Key): void => {
    setSelectedGroupedItem({
      element,
      key,
    });
    groupedActionModalState.open();
  };

  /**
   * Handles the confirmed action for a grouped modal.
   *
   * @param {TreeItem} element - The tree item associated with the action.
   * @param {Key} key - The key representing the specific action.
   * @returns {void}
   */
  /**
   * Handles the confirmed action for a grouped modal.
   *
   * @param {TreeItem} element - The tree item associated with the action.
   * @param {Key} key - The key representing the specific action.
   * @returns {void}
   */
  const handleGroupedModalActionConfirm = (
    element: TreeItem,
    key: Key,
  ): void => {
    switch (key) {
      case "authorize-certificate":
        dialog
          .open(
            t("dialog.authorizeCertificate"),
            t("dialog.authorizeCertificateMessage", {
              policyName: element?.data?.parentTitle || "N/D",
            }),
          )
          .then(() => {
            setIsLoadingTree(true);
            const value = element.data?.value as {
              application?: string;
              applicationDetail?: any;
            };

            securityPolicyService
              .authorizeSecurityPolicy(element?.data?.policyId || 0, {
                application: value.application || "",
                applicationDetail: value?.applicationDetail || {},
                authorizeCertificate: true,
              })
              .then(() => {
                fetchOpenNodeElements();
                setIsLoadingTree(false);
                groupedActionModalState.close();
              })
              .catch(() => {
                createNotification(
                  t("errors.genericError"),
                  NotificationTypes.DANGER,
                );
              })
              .finally(() => {
                setIsLoadingTree(false);
              });
          })
          .catch(() => {});

        break;
      case "authorize-hash":
        dialog
          .open(
            t("dialog.authorizeHash"),
            t("dialog.authorizeHashMessage", {
              policyName: element?.data?.parentTitle || "N/D",
            }),
          )
          .then(() => {
            setIsLoadingTree(true);
            const value = element.data?.value as {
              application?: string;
              applicationDetail?: any;
            };

            securityPolicyService
              .authorizeSecurityPolicy(element?.data?.policyId || 0, {
                application: value.application || "",
                applicationDetail: value?.applicationDetail || {},
                authorizeCertificate: false,
              })
              .then(() => {
                fetchOpenNodeElements();
                setIsLoadingTree(false);
                groupedActionModalState.close();
              })
              .catch(() => {
                createNotification(
                  t("errors.genericError"),
                  NotificationTypes.DANGER,
                );
              })
              .finally(() => {
                setIsLoadingTree(false);
              });
          })
          .catch(() => {});
        break;
      case "hide":
        dialog
          .open(t("dialog.hideLog"), t("dialog.hideLogMessage"))
          .then(() => {
            setIsLoadingTree(true);
            const value = element.data?.value as {
              application?: string;
              applicationDetail?: any;
            };

            activityService
              .hideActivity(
                element?.data?.policyId || "",
                value?.applicationDetail,
              )
              .then(() => {
                const parentKey = element.data?.parentKey;
                if (parentKey) {
                  const parent = findTreeItemRecursively(treeItems, parentKey);
                  if (parent && parent?.children?.length === 1) {
                    getGroupedPolicyLevel0().then(() => {
                      setSelectedGroupedItem(null);
                    });
                  } else if (parent) {
                    handleTreeItemToggle(parent, true);
                  }
                }
                setIsLoadingTree(false);
                groupedActionModalState.close();
              })
              .catch(() => {
                createNotification(
                  t("errors.genericError"),
                  NotificationTypes.DANGER,
                );
              })
              .finally(() => {
                setIsLoadingTree(false);
              });
          })
          .catch(() => {});
        break;
      case "restore":
        dialog
          .open(t("dialog.restoreLog"), t("dialog.restoreLogMessage"))
          .then(() => {
            setIsLoadingTree(true);
            const value = element.data?.value as {
              application?: string;
              applicationDetail?: any;
            };

            activityService
              .restoreActivity(
                element?.data?.policyId || "",
                value?.applicationDetail,
              )
              .then(() => {
                const parentKey = element.data?.parentKey;
                if (parentKey) {
                  const parent = findTreeItemRecursively(treeItems, parentKey);
                  if (parent && parent?.children?.length === 1) {
                    getGroupedPolicyLevel0().then(() => {
                      setSelectedGroupedItem(null);
                    });
                  } else if (parent) {
                    handleTreeItemToggle(parent, true);
                  }
                }
                setIsLoadingTree(false);
                groupedActionModalState.close();
              })
              .catch(() => {
                createNotification(
                  t("errors.genericError"),
                  NotificationTypes.DANGER,
                );
              })
              .finally(() => {
                setIsLoadingTree(false);
              });
          })
          .catch(() => {});
        break;
      default:
        break;
    }
  };

  /**
   * Represents a memoized variable called groupedItemsTableFields.
   *
   * This variable is created using useMemo hook and is used to store the value returned by the memoized function. The function searches for an open node in the treeItems array and returns
   * it. This variable is memoized to optimize performance by only recomputing the value when the dependencies, such as treeItems, change.
   */
  const groupedTreeItemsTableFields = useMemo(() => {
    // FInd open node in the tree
    const openNode = findDeepestTreeItemOpenNode(treeItems);
    if (openNode.node) {
      switch (openNode?.node?.data?.type) {
        case "policy":
          {
            const userActivityType = openNode?.node?.data?.userActivityType;
            if (userActivityType) {
              const items =
                userActivitiesGroupedPoliciesTreeItems[
                  userActivityType as unknown as UserActivityTypeEnum
                ];
              const foundItemByLevel = items?.find((item) => {
                return item.level === openNode.depth;
              });

              let tableFields = foundItemByLevel?.tableFields || [];

              if (!openNode.node.data.hasActionsEnabled) {
                // remove column actions from fields
                tableFields = tableFields.filter(
                  (field) => field.column !== "_actions",
                );
              }

              return {
                fields: tableFields,
                items: openNode.node.children || [],
              };
            }
          }
          break;
        default:
          break;
      }
    }

    return {
      fields: [],
      items: [],
    };
  }, [treeItems]);

  /**
   * Returns the current open node in the tree.
   *
   * @param {Array} treeItems - The array of tree items.
   * @returns {any} The current open node in the tree.
   */
  const currentOpenNode = useMemo(() => {
    return findDeepestTreeItemOpenNode(treeItems);
  }, [treeItems]);

  /**
   * @desc Memoized variable that returns the current open node parent table actions.
   */
  const currentOpenNodeParentTableActions: TableAction[] = useMemo(() => {
    const openNode = findDeepestTreeItemOpenNode(treeItems);
    if (openNode?.node?.data?.parentKey) {
      const parentNode = findTreeItemRecursively(
        treeItems,
        openNode?.node?.data?.parentKey,
      );
      if (parentNode?.data?.userActivityType && parentNode?.data?.level) {
        const userActivityType = parentNode.data?.userActivityType;
        if (userActivityType) {
          const items =
            userActivitiesGroupedPoliciesTreeItems[
              userActivityType as unknown as UserActivityTypeEnum
            ];
          const foundItemByLevel = items?.find((item) => {
            return (
              item.level.toString() === parentNode?.data?.level?.toString()
            );
          });

          if (parentNode?.data?.hasActionsEnabled) {
            const tableFields = foundItemByLevel?.tableFields || [];
            // remove column actions from fields
            const foundActions = tableFields.find(
              (field) => field.column === "_actions",
            );
            let actions = foundActions?.actions || [];
            if (
              groupingCriteria ===
              UserActivitiesGroupingCriteriaEnum.hiddenPolicy
            ) {
              actions = actions.filter((action) => action.key === "restore");
            } else {
              actions = actions.filter((action) => action.key !== "restore");
            }

            if (!actions.length) {
              return [];
            }

            const openNodeValue = openNode?.node?.data?.value as {
              applicationDetail?: {
                hash?: string;
                certificate?: string;
              };
            };

            const tableActions: TableAction[] = actions
              .map((action) => {
                switch (action.key) {
                  case "authorize-certificate":
                    if (
                      openNode?.node &&
                      openNodeValue.applicationDetail?.certificate
                    ) {
                      return {
                        icon: action.icon,
                        label: action.label,
                        onClick: () =>
                          handleGroupedItemMenuAction(
                            openNode?.node as TreeItem,
                            action.key,
                          ),
                      };
                    }
                    return null;
                  case "authorize-hash":
                    if (
                      openNode?.node &&
                      openNodeValue.applicationDetail?.hash
                    ) {
                      return {
                        icon: action.icon,
                        label: action.label,
                        onClick: () =>
                          handleGroupedItemMenuAction(
                            openNode?.node as TreeItem,
                            action.key,
                          ),
                      };
                    }
                    return null;
                  default:
                    return {
                      icon: action.icon,
                      label: action.label,
                      onClick: () =>
                        handleGroupedItemMenuAction(
                          openNode?.node as TreeItem,
                          action.key,
                        ),
                    };
                }
              })
              .filter((action) => action !== null) as TableAction[];

            return tableActions;
          }
        }
        return [];
      }
    }

    return [];
  }, [treeItems]);

  useEffect(() => {
    storeStorageItem(
      "sessionStorage",
      StorageItemKeyEnum.userActivitiesGroupingCriteria,
      groupingCriteria,
    );
    if (!isMounted) {
      return;
    }
    getGroupedPolicyLevel0().then(() => {});
  }, [groupingCriteria]);

  useEffect(() => {
    switch (groupingCriteria) {
      case UserActivitiesGroupingCriteriaEnum.policy:
      case UserActivitiesGroupingCriteriaEnum.hiddenPolicy:
        setGroupedFilters({
          ...initialFilters,
          count: groupedFilters.count,
          offset: groupedFilters.offset,
        });
        return;
      default:
        return;
    }
  }, [initialFilters, groupingCriteria]);

  useEffect(() => {
    if (!isMounted) {
      return;
    }
    getGroupedPolicyLevel0().then(() => {});
  }, [
    groupedFilters.search,
    groupedFilters.timespanOption,
    groupedFilters.severity,
    groupedFilters.activityType,
    groupedFilters.computerID,
    groupedFilters.userID,
    groupedFilters.notRead,
    groupedFilters.hidden,
  ]);

  useEffect(() => {
    fetchOpenNodeElements();
  }, [groupedFilters.offset, groupedFilters.count]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return {
    currentOpenNode,
    currentOpenNodeParentTableActions,
    dateFormatter,
    getGroupedPolicyLevel0,
    groupedActionModalState,
    groupedFilters,
    groupedTotal,
    groupedTreeItemsTableFields,
    groupingCriteria,
    handleGroupedItemMenuAction,
    handleGroupedModalActionConfirm,
    handleGroupedTableRowAction,
    handleTreeItemToggle,
    isLoadingTree,
    isLoadingTreeItem,
    selectedGroupedItem,
    setGroupedFilters,
    setGroupingCriteria,
    t,
    treeItems,
  };
}

export default useGroupedUserActivitiesTab;
